import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  private _router = inject(Router);
  private _auth = inject(AuthService);

  canActivate = (route: ActivatedRouteSnapshot): boolean => {
    const user_roles = this._auth.getUserRoles();

    if(user_roles.length > 0){
      if (user_roles.some(() => user_roles.includes('syndicate'))) {
        this._router.navigate(['/app/contracts']);
      }
      else {
        this._router.navigate(['/app/dashboard']);
      }

      return true;
    }

    return false;
  };
}
